import $ from 'jquery';
import { Pagination, Autoplay, Mousewheel, EffectFade } from 'swiper/modules';
import {Swiper} from 'swiper'
import 'swiper/css';

Swiper.use([Pagination, Autoplay, Mousewheel, EffectFade])

let stepsSlider = $('.js-steps-slider');

if (stepsSlider.length > 0) {
  stepsSlider.each(function () {
    let stepsSliderSwiper = new Swiper(this, {
      slidesPerView: 2,
      loop: false,
      centeredSlides: true,
      centeredSlidesBounds: true,
      navigation: {
        nextEl: '.swiper-button-next-custom',
        prevEl: '.swiper-button-prev-custom',
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: "auto",
        },
      },

      on: {
        resize: function enableOnlyMobile(stepsSliderSwiper){
          if(window.innerWidth > 1024){
            stepsSliderSwiper.enable()
          }else{
            stepsSliderSwiper.disable()
          }
        },
      },
    });

    stepsSliderSwiper.init();
  });
}

